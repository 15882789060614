import React from 'react'
import './participant.css'
import ParticipantList from '../ParticipantList/ParticipantList'

const Participant = () => {

  return (
    <div className='participant_container'>
      <ParticipantList />
    </div>
  )
}

export default Participant
